<template>
  <VcWidget
    v-if="!model.hidden"
    class="rebates order-last"
    size="lg"
    :title="model.title || $t('shared.catalog.product_details.rebates.title')"
    prepend-icon="sparkles"
  >
    <div v-if="!isSmallScreen" class="rebates__views">
      <button type="button" class="rebates__view" :disabled="isTableView" @click="toggleView">
        <VcIcon name="table" class="rebates__icon" />

        {{ $t("shared.catalog.product_details.rebates.table") }}
      </button>

      <button type="button" class="rebates__view" :disabled="!isTableView" @click="toggleView">
        <VcIcon name="list" class="rebates__icon" />

        {{ $t("shared.catalog.product_details.rebates.list") }}
      </button>
    </div>

    <RebatesList
      v-if="isSmallScreen || (!isSmallScreen && !isTableView)"
      :rebates="rebates"
      :fetching="fetchingRebates"
      size="lg"
    />

    <RebatesTable v-else :rebates="rebates" :sort="sort" :fetching="fetchingRebates" @apply-sorting="applySorting" />
  </VcWidget>
</template>

<script setup lang="ts">
import { useBreakpoints } from "@vueuse/core";
import { ref } from "vue";
import { BREAKPOINTS } from "@/core/constants";
import RebatesList from "./rebates-list.vue";
import RebatesTable from "./rebates-table.vue";
import type { Product } from "@/core/api/graphql/types";
import type { ISortInfo } from "@/core/types";

interface IEmits {
  (event: "applySorting", item: ISortInfo): void;
}

interface IProps {
  rebates: Product[];
  fetchingRebates: boolean;
  sort: ISortInfo;
  model: {
    title?: string;
    hidden?: boolean;
  };
}

const emit = defineEmits<IEmits>();

defineProps<IProps>();

const breakpoints = useBreakpoints(BREAKPOINTS);

const isSmallScreen = breakpoints.smaller("lg");
const isTableView = ref(!isSmallScreen.value);

function toggleView() {
  isTableView.value = !isTableView.value;
}

function applySorting(sortInfo: ISortInfo): void {
  emit("applySorting", sortInfo);
}
</script>

<style lang="scss">
.rebates {
  &__views {
    @apply mb-3 space-x-3;
  }

  &__view {
    @apply p-2 rounded bg-transparent text-sm text-neutral font-bold;

    &:hover {
      @apply bg-neutral-50;
    }

    &:disabled {
      @apply bg-neutral-100 text-neutral-950;
    }
  }

  &__icon {
    @apply me-1 size-5;

    *:disabled > & {
      @apply text-primary;
    }
  }
}
</style>
