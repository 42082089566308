<template>
  <ProductTitledBlock
    v-if="!model.hidden && ingedients"
    :title="model.title || $t('shared.catalog.product_details.ingedients_block_title')"
    icon="document-text"
  >
    <VcCollapsibleContent max-height="18.75rem" class="text-base text-neutral-600">
      <VcMarkdownRender :src="ingedients" />
    </VcCollapsibleContent>
  </ProductTitledBlock>
</template>

<script setup lang="ts">
import { find } from "lodash";
import { computed } from "vue";
import ProductTitledBlock from "../product-titled-block.vue";
import type { Product } from "@/core/api/graphql/types";

interface IProps {
  product: Product;
  model: {
    title?: string;
    hidden?: boolean;
  };
}

const props = defineProps<IProps>();

const ingedients = computed<string | undefined>(
  () => find(props.product.descriptions, { reviewType: "IngredientList" })?.content,
);
</script>
