<template>
  <div :class="['rebates-list', `rebates-list--size--${size}`]">
    <div v-for="(group, index) in rebateGroups" :key="index" class="rebates-list__group">
      <span class="rebates-list__title">{{ group.programType.value }}</span>
      <div class="rebates-list__rebates">
        <template v-for="(item, index) in group.items" :key="index">
          <div class="rebates-list__properties">
            <div class="col-start-1 row-start-1 lg:hidden">
              <VcIcon name="plus" size="xxs" class="rebates-list__rebates__icon" />
            </div>
            <div class="col-start-1 row-start-1 grid grid-cols-subgrid lg:col-span-3 lg:col-start-1">
              <VcProperty
                v-for="property in filter(item.properties, (r) => r.name !== 'program_type')"
                :key="property.name"
                :label="property.label!"
                class="text-base"
              >
                {{ property.value }}
              </VcProperty>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { filter } from "lodash";
import { computed } from "vue";
import type { Product, PropertyDictionaryItem } from "@/core/api/graphql/types";

const props = withDefaults(defineProps<IProps>(), {
  size: "md",
});

interface IProps {
  rebates: Product[];
  size?: "xs" | "sm" | "md" | "lg";
}

type RebateGroup = {
  programType: PropertyDictionaryItem;
  items: Product[];
};

type ProgramTypeGroup = {
  i: number;
  programType: PropertyDictionaryItem;
};

const rebateGroups = computed(() => {
  return props.rebates.reduce<RebateGroup[]>((groups, rebate) => {
    const prop = rebate.properties.find((p) => p.name === "program_type");
    if (prop === undefined || prop?.propertyDictionaryItems?.items == undefined) {
      return groups;
    }

    const group = prop.propertyDictionaryItems.items
      .sort((a, b) => {
        if (a.sortOrder > b.sortOrder) {
          return 1;
        }

        if (a.sortOrder < b.sortOrder) {
          return -1;
        }

        if (a.value === undefined && b.value === undefined) {
          return 0;
        }

        if (a.value === undefined) {
          return -1;
        }

        if (b.value === undefined) {
          return 1;
        }

        return a.value.localeCompare(b.value);
      })
      .map((v, i): ProgramTypeGroup => {
        return { i: i, programType: v };
      })
      .find((g) => g.programType.id === prop?.valueId);

    if (group === undefined) {
      return groups;
    }

    (groups[group.i] = groups[group.i] || { programType: Object.assign({}, group.programType), items: [] }).items.push(
      structuredClone(rebate),
    );

    return groups;
  }, []);
});
</script>

<style lang="scss">
.rebates-list {
  @apply flex flex-col gap-3 lg:gap-5;

  &--size {
    &--xs {
      --title-text: theme("fontSize.sm");
    }

    &--sm {
      --title-text: theme("fontSize.sm");
    }

    &--md {
      --title-text: theme("fontSize.base");
    }

    &--lg {
      --title-text: theme("fontSize.base");
    }
  }

  &__title {
    @apply flex flex-col justify-center min-w-0 grow text-[length:--title-text] font-bold uppercase;
  }

  &__group {
    @apply flex flex-col gap-1 lg:gap-2;
  }

  &__rebates {
    @apply grid w-full grid-cols-1 pl-5 gap-4 lg:gap-2;

    &__icon {
      @apply -ms-4 mt-1.5;

      /* @media (min-width: theme("screens.lg")) {
        --p-x: theme("padding.7");
      } */
    }
  }

  &__properties {
    @apply grid w-full grid-cols-1 lg:grid-cols-3 lg:gap-5;
  }
}
</style>
