<template>
  <div class="rebates-table">
    <VcTable
      :items="rebates"
      :columns="columns"
      :sort="sort"
      :loading="fetching"
      layout="table-fixed min-w-full w-auto"
      @header-click="applySorting"
    >
      <template #desktop-skeleton>
        <tr v-for="rowIndex in 5" :key="rowIndex" class="rebates-table__row even:bg-neutral-50">
          <td v-for="columnIndex in columns.length" :key="columnIndex" class="p-5">
            <div class="h-6 animate-pulse bg-neutral-200" />
          </td>
        </tr>
      </template>

      <template #desktop-body>
        <tr v-for="(rebate, rebateIndex) in rebates" :key="rebate.code" class="rebates-table__row">
          <td
            v-for="(property, index) in rebateProperties"
            :key="index"
            class="rebates-table__col rebates-table__col--property"
          >
            {{ property.values[rebateIndex] }}
          </td>
        </tr>
      </template>
    </VcTable>
  </div>
</template>

<script setup lang="ts">
import { flatten, sortBy, uniqBy } from "lodash";
import { computed } from "vue";
import { PropertyType } from "@/core/api/graphql/types";
import { getPropertyValue, getPropertiesGroupedByName, sortRebates } from "@/core/utilities";
import type { Product } from "@/core/api/graphql/types";
import type { ISortInfo } from "@/core/types";

interface IEmits {
  (event: "applySorting", item: ISortInfo): void;
}

interface IProps {
  rebates: Product[];
  sort: ISortInfo;
  fetching: boolean;
}

interface IProductProperties {
  name: string;
  label: string;
  values: string[];
}

const emit = defineEmits<IEmits>();

const props = defineProps<IProps>();

const rebates = computed(() => sortRebates(props.rebates));
const rebateProperties = computed<IProductProperties[]>(() => {
  const properties: IProductProperties[] = [];

  const propertiesCombined = flatten(rebates.value.map((rebate) => getProperties(rebate)));

  const names = uniqBy(
    propertiesCombined.map((prop) => {
      return {
        name: prop.name,
        label: prop.label,
      };
    }),
    "name",
  );

  names.forEach(({ name, label }) => {
    properties.push({
      name,
      label,
      values: rebates.value.map((rebate) => {
        const property = rebate.properties.find((item) => item.name === name);
        return property
          ? getPropertyValue(property, { numberOptions: { maximumFractionDigits: 6 } }) ?? "\u2013"
          : "\u2013";
      }),
    });
  });

  return properties;
});

const propertyColumns = computed<ITableColumn[]>(() =>
  rebateProperties.value.map((item) => ({
    id: item.name,
    title: item.label,
    sortable: true,
    align: "center",
    classes: "min-w-24 w-24",
  })),
);

const columns = computed<ITableColumn[]>(() => [...propertyColumns.value]);

function getProperties(rebate: Product) {
  return Object.values(
    getPropertiesGroupedByName(sortBy(rebate.properties, ["displayOrder", "name"]) ?? [], PropertyType.Product),
  );
}

function applySorting(sortInfo: ISortInfo): void {
  emit("applySorting", sortInfo);
}
</script>

<style lang="scss">
.rebates-table {
  @apply overflow-x-auto rounded border;

  &__row {
    @apply even:bg-neutral-50 hover:bg-neutral-100;
  }

  &__col {
    &--title {
      @apply overflow-hidden text-ellipsis py-2.5 ps-4;
    }

    &--property {
      @apply px-4 py-2.5 text-center;
    }
  }
}
</style>
