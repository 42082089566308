<template>
  <button
    v-if="showScrollButton"
    type="button"
    class="fixed bottom-10 right-3 z-20 opacity-60 hover:opacity-100"
    @click="scrollToTop"
  >
    <VcIcon class="text-primary" name="arrow-circle-up" size="xl" />
  </button>
</template>

<script setup lang="ts">
import { ref } from "vue";
import type { iframeResizer } from "@iframe-resizer/child";

const showScrollButton = ref(false);
const scrollLeft = ref(0);

const scrollToTop = () => {
  window.scrollTo(0, 0);
  if ("parentIFrame" in window) {
    (window.parentIFrame as iframeResizer.IFramePage).scrollTo(scrollLeft.value, 0);
    return;
  }
  (window as Window).scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

if ("parentIFrame" in window) {
  (window.parentIFrame as iframeResizer.IFramePage).getParentProps((props: iframeResizer.ParentProps) => {
    if (props.viewport.pageTop > 20) {
      showScrollButton.value = true;
    } else {
      showScrollButton.value = false;
    }
    scrollLeft.value = props.viewport.pageLeft;
  });
} else {
  (window as Window).onscroll = () => {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      showScrollButton.value = true;
    } else {
      showScrollButton.value = false;
    }
  };
}
</script>
