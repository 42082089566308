import { reduce, sortBy } from "lodash";
import type { Product } from "@/core/api/graphql/types";

export function sortRebates(rebates: Product[]): Product[] {
  if (!rebates || rebates.length == 0) {
    return [];
  }

  const programTypeSortPositions = reduce(
    sortBy(rebates[0].properties.find((p) => p.name === "program_type")?.propertyDictionaryItems?.items ?? [], [
      "sortOrder",
      "value",
    ]),
    (result, value, index) => {
      result[value.id] = index;
      return result;
    },
    {} as { [key: string]: number },
  );

  return sortBy(rebates, [
    (rebate) => {
      const programType = rebate.properties.find((p) => p.name === "program_type");
      if (programType && programType.valueId) {
        return programTypeSortPositions[programType.valueId];
      }
      return undefined;
    },
    (rebate) => {
      return rebate.properties.find((p) => p.name === "rate")?.value;
    },
  ]);
}

export function formateRebate(rate: string | null | undefined, basis: string | null | undefined): string {
  rate = rate ? rate : "[??? Rate]";
  basis = basis ? basis : "[??? Basis]";

  let index = basis.indexOf("$");
  if (index >= 0) {
    ++index;
    return `${basis.slice(0, index)}${rate}${basis.slice(index)}`;
  }

  index = basis.indexOf("%");
  if (index >= 0) {
    return `${basis.slice(0, index)}${rate}${basis.slice(index)}`;
  }
  return `${rate} ${basis}`;
}
